import { Link } from "gatsby"
import React, { Fragment } from "react"
import "./selector.scss"

class Selector extends React.Component {
  constructor(props) {
    super(props)
  }

  selectChanged = e => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(e.currentTarget.name, e.currentTarget.value)
    }
  }

  render() {
    const { title, slug, proficiencies, description } = this.props

    return (
      <div className="selector">
        <h3 className="selector__title">
          {title}
          <span className="information tooltip">
            <span className="tooltiptext">{description}</span>
          </span>
        </h3>
        <div className="switch-field">
          {proficiencies.map(item => (
            <Fragment key={slug + "-" + item.node.slug}>
              <input
                type="radio"
                id={slug + "-" + item.node.slug}
                name={slug}
                value={item.node.value}
                defaultChecked={
                  (typeof window !== "undefined" &&
                    parseInt(sessionStorage.getItem(slug), 10) ===
                      item.node.value) ||
                  item.node.value === 1
                    ? true
                    : false
                }
                onChange={this.selectChanged}
              />
              <label htmlFor={slug + "-" + item.node.slug}>
                {item.node.title}
              </label>
            </Fragment>
          ))}
          <div className="switch__indicator noSelect"></div>
        </div>
      </div>
    )
  }
}

export default Selector
