import { Link } from "gatsby"
import React from "react"
import "./proficiency.scss"

const Proficiency = ({ slug, title, description, value }) => {
  return (
    <div id={slug} className="proficiency">
      <div className="proficiency-indicator">
        <ul className="proficiency-steps">
          {[1, 2, 3, 4].map(item => (
            <li key={item}>
              <div
                className={`proficiency-steps__circle${
                  item <= value ? " active" : ""
                }`}
              ></div>
            </li>
          ))}
        </ul>
      </div>
      <div className="proficiency-details">
        <p className="proficiency-title">{title}</p>
        <p className="proficiency-description">{description}</p>
      </div>
    </div>
  )
}

export default Proficiency
