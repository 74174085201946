import React from "react"
import { Link } from "gatsby"
import "./breadcrumb.scss"

const Breadcrumb = ({ current, pageContext }) => (
  <ul className="breadcrumb">
    <li className={current >= 1 ? "current" : ""}>
      <svg
        width="28"
        height="32"
        viewBox="0 0 28 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.3334 12L27.3334 2.66667H3.33341V1.33333C3.33341 0.597333 2.73608 0 2.00008 0C1.26408 0 0.666748 0.597333 0.666748 1.33333V30.6667C0.666748 31.4027 1.26408 32 2.00008 32C2.73608 32 3.33341 31.4027 3.33341 30.6667V21.3333H27.3334L23.3334 12Z" />
      </svg>
      {current >= 1 ? (
        <Link className="label" to="/list">
          1. Desired Role
        </Link>
      ) : (
        <span className="label">1. Desired Role</span>
      )}
    </li>
    <li className={current >= 2 ? "current" : ""}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M30.6667 4H16V6.66667H30.6667C31.0203 6.66667 31.3594 6.52619 31.6095 6.27614C31.8595 6.02609 32 5.68696 32 5.33333C32 4.97971 31.8595 4.64057 31.6095 4.39052C31.3594 4.14048 31.0203 4 30.6667 4Z" />
        <path d="M1.33333 6.66667H8V10.6667H13.3333V0H8V4H1.33333C0.979711 4 0.640573 4.14048 0.390524 4.39052C0.140476 4.64057 0 4.97971 0 5.33333C0 5.68696 0.140476 6.02609 0.390524 6.27614C0.640573 6.52619 0.979711 6.66667 1.33333 6.66667Z" />
        <path d="M30.6667 25.3333H16V28H30.6667C31.0203 28 31.3594 27.8595 31.6095 27.6095C31.8595 27.3594 32 27.0203 32 26.6666C32 26.313 31.8595 25.9739 31.6095 25.7238C31.3594 25.4738 31.0203 25.3333 30.6667 25.3333Z" />
        <path d="M1.33333 28H8V32H13.3333V21.3333H8V25.3333H1.33333C0.979711 25.3333 0.640573 25.4738 0.390524 25.7238C0.140476 25.9739 0 26.313 0 26.6666C0 27.0203 0.140476 27.3594 0.390524 27.6095C0.640573 27.8595 0.979711 28 1.33333 28Z" />
        <path d="M30.6667 14.6667H26.6667V17.3334H30.6667C31.0204 17.3334 31.3595 17.1929 31.6096 16.9428C31.8596 16.6928 32.0001 16.3536 32.0001 16C32.0001 15.6464 31.8596 15.3073 31.6096 15.0572C31.3595 14.8072 31.0204 14.6667 30.6667 14.6667Z" />
        <path d="M1.33333 17.3334H18.6667V21.3334H24V10.6667H18.6667V14.6667H1.33333C0.979711 14.6667 0.640573 14.8072 0.390524 15.0572C0.140476 15.3073 0 15.6464 0 16C0 16.3536 0.140476 16.6928 0.390524 16.9428C0.640573 17.1929 0.979711 17.3334 1.33333 17.3334Z" />
      </svg>
      {current >= 2 ? (
        <Link
          className="label"
          to={
            "/" +
            pageContext.businessFunctionSlug +
            "/" +
            pageContext.roleSlug +
            "/role"
          }
        >
          2. Self Assess
        </Link>
      ) : (
        <span className="label">2. Self Assess</span>
      )}
    </li>
    <li className={current >= 3 ? "current" : ""}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 22.5975L22.0094 26.3901L20.4319 19.2L25.7653 14.3012L18.7042 13.6691L16 6.87407V22.5975ZM32 12.1679L23.2864 20.1481L25.9155 32L16 25.679L6.08451 32L8.71361 20.1481L0 12.1679L11.493 11.1407L16 0L20.507 11.1407L32 12.1679Z" />
      </svg>
      <span className="label">3. Share Results</span>
    </li>
  </ul>
)

export default Breadcrumb
