import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Breadcrumb from "../components/breadcrumb"

import Selector from "../components/selector"
import Proficiency from "../components/proficiency"

const RolePage = ({ data, pageContext }) => {
  const { id, name, roleSlug, businessFunctionSlug } = pageContext
  const {
    title,
    topCompetencies,
    businessFunction,
    description,
    forTheIndividualThatEnjoys,
    file,
  } = data.contentfulRole
  const proficiencies = data.allContentfulProficiency.edges
  const queryString =
    typeof window !== "undefined" ? window.location.search : ""

  const allCompetencies = topCompetencies.sort(function(a, b) {
    if (a.competency.title < b.competency.title) {
      return -1
    }
    if (a.competency.title > b.competency.title) {
      return 1
    }
    return 0
  })

  const fileURL = file !== null ? file.file.url : ""

  return (
    <Layout>
      <Breadcrumb current="2" pageContext={pageContext} />
      <form
        className="role-form"
        action={"/" + businessFunctionSlug + "/" + roleSlug + "/result/"}
        method="get"
      >
        <div className="role">
          <div className="role-column role-column__one">
            <div className="border-wrapper">
              <div className="container">
                <p className="role__item-subtitle">{businessFunction.title}</p>
                <h1 className="role__item-title">{title}</h1>
                <p className="role__item-description">
                  {description.description}
                </p>
                <h4 className="role-list__item-listheading">
                  FOR THE INDIVIDUAL THAT
                </h4>
                <ul className="role__item-bullet">
                  {forTheIndividualThatEnjoys !== null && forTheIndividualThatEnjoys.map((item, index) => (
                    <li key={item + index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="container">
              {fileURL.length > 0 && (
                <a
                  className="role__item-link"
                  href={fileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Download Role Description</span>
                </a>
              )}
            </div>
            <div className="accordions">
              <input type="checkbox" name="accordions" id="tabone" />
              <label htmlFor="tabone">
                Understand Proficiency levels
                <img src={"/collapse.svg"} className="icon-default" alt="" />
                <img
                  src={"/collapse-hover.svg"}
                  className="icon-hover"
                  alt=""
                />
                <img
                  src={"/collapse-active.svg"}
                  className="icon-active"
                  alt=""
                />
              </label>
              <div className="accordion">
                <div className="container">
                  {proficiencies.map(item => (
                    <Proficiency
                      key={item.node.slug}
                      title={item.node.title}
                      slug={item.node.title}
                      value={item.node.value}
                      description={item.node.description}
                    />
                  ))}
                  {proficiencies.map(item => (
                    <style
                      key={"sytle" + item.node.slug}
                      dangerouslySetInnerHTML={{
                        __html: `
                      input[value="${item.node.value}"]:checked ~ .switch__indicator:after {
                          content: '${item.node.title}';
                      }
                    `,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="role-column role-column__two">
            <div className="container">
              <p className="start-heading">
                Please complete all competency self assessment
              </p>
              <p>Top {allCompetencies.length || 0} competencies for this role</p>
              {allCompetencies.map(item => (
                <Selector
                  key={item.competency.slug}
                  title={item.competency.title}
                  description={item.competency.description.description}
                  slug={item.competency.slug}
                  proficiencies={proficiencies}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="buttons-wrapper">
          <Link to="/list" className="previous-button small">
            <svg
              width="20"
              height="15"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19.1831 6.86078L2.95812 6.86078L7.81 1.84266C8.12875 1.53578 8.12875 1.03703 7.81 0.730158C7.49125 0.423283 6.975 0.423282 6.65687 0.730157L-1.25258e-06 7.66391L6.55375 14.6464C6.71312 14.8008 6.92187 14.8783 7.13 14.8783C7.33812 14.8783 7.54812 14.8014 7.70687 14.6464C8.02625 14.3395 8.02625 13.842 7.70687 13.5352L2.905 8.43453L19.1831 8.43453C19.6344 8.43453 20 8.08266 20 7.64703C20 7.21266 19.6344 6.86078 19.1831 6.86078Z" />
            </svg>
            Back
          </Link>
          <input
            className="next-button small"
            type="submit"
            value="View Results"
          />
        </div>
      </form>
    </Layout>
  )
}

export const pageQuery = ({ pageContext }) => graphql`
  query($id: String) {
    contentfulRole(id: { eq: $id }) {
      id
      title
      description {
        description
      }
      file {
        file {
          url
        }
      }
      businessFunction {
        title
        slug
      }
      topCompetencies {
        proficiency {
          title
          slug
          value
        }
        competency {
          title
          slug
          description {
            description
          }
        }
      }
      forTheIndividualThatEnjoys
    }
    allContentfulProficiency(sort: { fields: value, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          value
          description
        }
      }
    }
  }
`

export default RolePage
